import React, { useEffect } from 'react';
import HolidayPackage from '../../donations/holidayPackage';
import css from './pessah.module.scss';

const PessahInfo = () => {


    useEffect(() => {
        document.body.classList.add("pessah-info-body");
        return () => {
            document.body.classList.remove("pessah-info-body");
        };
    }, []);


    return (
        <div className={css.Pessah}>
            <div className={`${css.holidayPack} py-4`}>
                <div className="row">
                    <div className="col-sm-12 col-md-12 text-center">
                        <h2 className={`${css.HolidayPackHeader}`}>
                            200 FAMILLES DÉMUNIES DE TEL AVIV
                        </h2>
                        <h3 className={`${css.HolidayPackSub}`}>
                            Chaque famille reçoit une valeur de{" "}
                            <span className={css.HolidayRed}>650 € </span>en produits
                            alimentaires
                        </h3>
                    </div>
                    <HolidayPackage/>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-sm-12 col-md-5">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 text-center mt-3 mb-3">
                                <div className={`${css.HolidayPackListHeader}`}>
                                    LISTE DES PRODUITS PAR FAMILLE
                                </div>
                            </div>
                        </div>

                        <table className="table table-bordered border-primary">
                            <thead>
                            <tr></tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    <div className={`${css.HolidayProduct}`}>Vin:</div>
                                </td>
                                <td>6 bt × 6 €</td>
                                <td>
                                    <div className={`${css.HolidayProductPrice}`}>36 €</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className={`${css.HolidayProduct}`}>
                                        Jus de raisin:
                                    </div>
                                </td>
                                <td>6 bt × 3 €</td>
                                <td>
                                    <div className={`${css.HolidayProductPrice}`}>18 €</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className={`${css.HolidayProduct}`}>Viande:</div>
                                </td>
                                <td>12 kg × 10 €</td>
                                <td>
                                    <div className={`${css.HolidayProductPrice}`}>120 €</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className={`${css.HolidayProduct}`}>Poulet:</div>
                                </td>
                                <td>15 poulets × 6 €</td>
                                <td>
                                    <div className={`${css.HolidayProductPrice}`}>90 €</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className={`${css.HolidayProduct}`}>Poissons:</div>
                                </td>
                                <td>8 kg × 12 €</td>
                                <td>
                                    <div className={`${css.HolidayProductPrice}`}>96 €</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className={`${css.HolidayProduct}`}>Oeufs:</div>
                                </td>
                                <td>5 plaques × 6 €</td>
                                <td>
                                    <div className={`${css.HolidayProductPrice}`}>30 €</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className={`${css.HolidayProduct}`}>Fruits et Légumes:</div>
                                </td>
                                <td></td>
                                <td>
                                    <div className={`${css.HolidayProductPrice}`}>100 €</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className={`${css.HolidayProduct}`}>
                                        Habits:
                                    </div>
                                </td>
                                <td></td>
                                <td>
                                    <div className={`${css.HolidayProductPrice}`}>160 €</div>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <div className="row">
                            <div className="col-sm-12 col-md-12 text-center">
                                <div className={`${css.HolidayPackListTotal}`}>
                                    1 FAMILLE = 650 €
                                </div>
                                <div className={`${css.HolidayPackListTotalSub}`}>
                                    TOTAL POUR 200 FAMILLES : 130 000 €
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row justify-content-md-center">
                <div className="col-sm-12 col-md-5">
                    <div className={`${css.holidayPackBottom} py-2`}>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 text-center">
                                <h2 className={`${css.HolidayPackHeader}`}>
                                    DÉPENSE POUR 200 FAMILLES
                                </h2>
                            </div>
                        </div>

                        <table className="table table-bordered border-primary">
                            <thead>
                            <tr></tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    <div className={`${css.HolidayProduct}`}>
                                        Fruits/légumes:
                                    </div>
                                </td>
                                <td>
                                    <div className={`${css.HolidayProductPrice}`}>20 000 €</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className={`${css.HolidayProduct}`}>Vin:</div>
                                </td>
                                <td>
                                    <div className={`${css.HolidayProductPrice}`}>7 200 €</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className={`${css.HolidayProduct}`}>Poissons:</div>
                                </td>
                                <td>
                                    <div className={`${css.HolidayProductPrice}`}>19 200 €</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className={`${css.HolidayProduct}`}>Viande:</div>
                                </td>
                                <td>
                                    <div className={`${css.HolidayProductPrice}`}>24 000 €</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className={`${css.HolidayProduct}`}>Poulets:</div>
                                </td>
                                <td>
                                    <div className={`${css.HolidayProductPrice}`}>18 000 €</div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PessahInfo;
