import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import store from '../store';
import { RESET_USER_DETAILS } from '../store/constants/userConstants';

const token = localStorage.getItem("token");


axios.defaults.headers.common["Authorization"] = token ? `Bearer ${token}` : '';

const api: AxiosInstance = axios.create({
  baseURL: process.env.NODE_ENV === 'development'
      ? "http://127.0.0.1:9000"
      : "https://yeshiva-api.herokuapp.com",
  headers: {
    "Content-Type": "application/json",
    Authorization: token ? `Bearer ${token}` : '',
  },
});


api.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      if (error.response && error.response.status === 401) {
        store.dispatch({ type: RESET_USER_DETAILS });
      }
      return Promise.reject(error);
    }
);

export default api;
