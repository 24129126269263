import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import hands from '../../nav/images/donate-icon.svg';
import breslev from '../../pages/teasers/images/breslev_logo.png';
import css from './pessah.module.scss';
import styles from '../../pages/teasers/css/rosh.module.scss';
import cssNav from '../../nav/css/nav.module.scss';

const Pessah = () => {

  useEffect(() => {
    document.body.classList.add("pessah-body");
    return () => {
      document.body.classList.remove("pessah-body");
    };
  }, []);

  return (
    <div className={`${css.Pessah} row justify-content-md-center`}>
      <div className="col-sm-12 col-md-5 text-center mt-5">
        <div className={css.Joyeux}>Action</div>
        <div
          className={`${css.Heading} animate__animated animate__fadeIn animate__delay-1s`}
        >
          Pessah
        </div>
        <div className={`${styles.Hebrew}`}>  פסח כשר ושמח </div>
        <div className={`${css.Year}`}>5785</div>
        <div className={`${styles.YearFrench}`}>DU 12 AVRIL AU SOIR AU 20 AVRIL 2025</div>
        <img
          src={breslev}
          height="60"
          className="me-2"
          alt="Breslev Tel Aviv"
        />
        <div className={`${css.holidayFooter} pb-5 mt-2`}>
          <div className="row justify-content-md-center ">
            <div className="col-sm-12 col-md-12 text-center">
              <div
                className={`${css.SouccotCta} row justify-content-md-center`}
              >
                <div className="col-sm-12 col-md-5 text-center">
                  <Link to={`/les-fete/pessah`}>
                    <div
                      className={`btn btn-warning btn-lg ${cssNav.giveADonation}`}
                    >
                      <img
                        src={hands}
                        height="30"
                        className="me-2"
                        alt="hands"
                      />
                      Aidez-nous
                    </div>
                  </Link>
                </div>
              </div>
              <h2 className={`${css.HolidayPackHeader}`}>
                200 FAMILLES DÉMUNIES DE TEL AVIV
              </h2>
              <div className={`${css.HolidayPackSub}`}>
                Chaque famille reçoit une valeur de{" "}
                <span className={css.HolidayRed}>650 € </span>en produits
                alimentaires
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-md-7 text-center">
        {/*<img*/}
        {/*  src={succoth_picture}*/}
        {/*  className="img-fluid"*/}
        {/*  alt="Happy People Sukkot"*/}
        {/*/>*/}
      </div>
    </div>
  );
};

export default Pessah;
